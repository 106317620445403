import React from 'react';
import { graphql } from 'gatsby';

import { Layout, SEO } from '../components';
import { ModulesSection } from '../sections';

const Modules = ({ data }) => {
  let modulesArray = [];
  const modules = data.modules.edges.map((module) => module.node);

  for (let i=0; i < modules.length; i++) {

    if (modules[i].title !== "Topics")
    {
      modulesArray.push(modules[i])
    }
  }

  const modulesData = {
    section: modulesArray,
  };


  return (
    <Layout>
      <SEO title="Modules" noIndex={true} />
      <ModulesSection data={modulesData} />
    </Layout>
  );
};

export default Modules;

const query = graphql`
  query ModulesQuery {
    modules: allDatoCmsModule {
      edges {
        node {
          id
          title
          slug
          position
          comingSoon
          shortDescription
          description
          image {
            alt
            url
          }
          subCategory {
            name
            slug
          }
          seoMetaTags {
            ...GatsbyDatoCmsSeoMetaTags
          }
        }
      }
    }
  }
`;
export { query };
